import { Template } from '@/types'
import MBM_001 from 'public/templates/en/MBM_001.jpg'
import MBM_002 from 'public/templates/en/MBM_002.jpg'
import MBM_003 from 'public/templates/en/MBM_003.jpg'
import MBM_004 from 'public/templates/en/MBM_004.jpg'
import MBM_005 from 'public/templates/en/MBM_005.jpg'
import MBM_006 from 'public/templates/en/MBM_006.jpg'
import MBM_007 from 'public/templates/en/MBM_007.jpg'
import MBM_008 from 'public/templates/en/MBM_008.jpg'
import MBM_009 from 'public/templates/en/MBM_009.jpg'
import MBM_010 from 'public/templates/en/MBM_010.jpg'
import MBM_011 from 'public/templates/en/MBM_011.jpg'
import MBM_012 from 'public/templates/en/MBM_012.jpg'
import MBM_013 from 'public/templates/en/MBM_013.jpg'
import MBM_014 from 'public/templates/en/MBM_014.jpg'
import MBM_015 from 'public/templates/en/MBM_015.jpg'
import MBM_016 from 'public/templates/en/MBM_016.jpg'
import MBM_017 from 'public/templates/en/MBM_017.jpg'
import MBM_018 from 'public/templates/en/MBM_018.jpg'
import MBM_019 from 'public/templates/en/MBM_019.jpg'
import MBM_020 from 'public/templates/en/MBM_020.jpg'
import MBM_021 from 'public/templates/en/MBM_021.jpg'
import MBM_022 from 'public/templates/en/MBM_022.jpg'
import MBM_023 from 'public/templates/en/MBM_023.jpg'
import MBM_024 from 'public/templates/en/MBM_024.jpg'
import MBM_025 from 'public/templates/en/MBM_025.jpg'
import MBM_026 from 'public/templates/en/MBM_026.jpg'
import MBM_027 from 'public/templates/en/MBM_027.jpg'
import MBM_028 from 'public/templates/en/MBM_028.jpg'
import MBM_029 from 'public/templates/en/MBM_029.jpg'
import MBM_030 from 'public/templates/en/MBM_030.jpg'
import MBM_031 from 'public/templates/en/MBM_031.jpg'
import MBM_032 from 'public/templates/en/MBM_032.jpg'
import MBM_033 from 'public/templates/en/MBM_033.jpg'
import MBM_034 from 'public/templates/en/MBM_034.jpg'
import MBM_035 from 'public/templates/en/MBM_035.jpg'
import MBM_036 from 'public/templates/en/MBM_036.jpg'
import MBM_037 from 'public/templates/en/MBM_037.jpg'
import MBM_038 from 'public/templates/en/MBM_038.jpg'
import MBM_039 from 'public/templates/en/MBM_039.jpg'

export const TEMPLATE_LIST: Template[] = [
  {
    id: 'MBM_039',
    src: MBM_039,
    price: 0,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#000000',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_001',
    src: MBM_001,
    price: 59,
    isTrending: true,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#A4592C',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_002',
    src: MBM_002,
    price: 49,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#A4592C',
      TEXT_COLOR: '#2a3740',
    },
  },
  {
    id: 'MBM_016',
    src: MBM_016,
    price: 49,
    isTrending: true,
    metadata: {
      X_PAGE_START: 90,
      X_PAGE_END: 704,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#E7A581',
      TEXT_COLOR: '#545454',
    },
  },
  {
    id: 'MBM_003',
    src: MBM_003,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 74,
      X_PAGE_END: 720,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#CF6950',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_004',
    src: MBM_004,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 70,
      X_PAGE_END: 724,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#28245B',
      TEXT_COLOR: '#000000',
    },
  },

  {
    id: 'MBM_031',
    src: MBM_031,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 70,
      X_PAGE_END: 724,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#000000',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_032',
    src: MBM_032,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 80,
      X_PAGE_END: 714,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#000000',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_033',
    src: MBM_033,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 70,
      X_PAGE_END: 724,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#000000',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_034',
    src: MBM_034,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 65,
      X_PAGE_END: 729,
      Y_HEADER_ICON_START: 85,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#231F20',
      TEXT_COLOR: '#231F20',
    },
  },
  {
    id: 'MBM_035',
    src: MBM_035,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 85,
      X_PAGE_END: 709,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#1A1A1A',
      TEXT_COLOR: '#1A1A1A',
    },
  },
  {
    id: 'MBM_036',
    src: MBM_036,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 80,
      X_PAGE_END: 714,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#000000',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_037',
    src: MBM_037,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#6B646B',
      TEXT_COLOR: '#585158',
    },
  },
  {
    id: 'MBM_005',
    src: MBM_005,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 60,
      X_PAGE_END: 734,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#3D752A',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_006',
    src: MBM_006,
    price: 59,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#EEBA2B',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_007',
    src: MBM_007,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#8CC3C4',
      TEXT_COLOR: '#27464E',
    },
  },
  {
    id: 'MBM_008',
    src: MBM_008,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#EEBA2B',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_009',
    src: MBM_009,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 70,
      X_PAGE_END: 724,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#565A5B',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_010',
    src: MBM_010,
    price: 39,
    isTrending: true,
    metadata: {
      X_PAGE_START: 65,
      X_PAGE_END: 729,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#644F59',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_011',
    src: MBM_011,
    price: 39,
    isTrending: true,
    metadata: {
      X_PAGE_START: 65,
      X_PAGE_END: 729,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#A3720F',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_012',
    src: MBM_012,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 70,
      X_PAGE_END: 724,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#80736F',
      TEXT_COLOR: '#332A24',
    },
  },
  {
    id: 'MBM_013',
    src: MBM_013,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 90,
      X_PAGE_END: 704,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#EAA416',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_014',
    src: MBM_014,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 85,
      X_PAGE_END: 709,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#EB98A0',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_015',
    src: MBM_015,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 85,
      X_PAGE_END: 709,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#6D876D',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_017',
    src: MBM_017,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#CB182D',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_018',
    src: MBM_018,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 70,
      X_PAGE_END: 724,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#FFFBFA',
      TEXT_COLOR: '#EFEEE9',
    },
  },
  {
    id: 'MBM_019',
    src: MBM_019,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 85,
      X_PAGE_END: 709,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#222222',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_020',
    src: MBM_020,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#F1D780',
      TEXT_COLOR: '#B6C2CE',
    },
  },
  {
    id: 'MBM_021',
    src: MBM_021,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 85,
      X_PAGE_END: 709,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#E3D5B8',
      TEXT_COLOR: '#B3AA99',
    },
  },
  {
    id: 'MBM_022',
    src: MBM_022,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 85,
      X_PAGE_END: 709,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#F8AC39',
      TEXT_COLOR: '#545454',
    },
  },
  {
    id: 'MBM_023',
    src: MBM_023,
    price: 59,
    isTrending: false,
    metadata: {
      X_PAGE_START: 80,
      X_PAGE_END: 714,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#E39BA7',
      TEXT_COLOR: '#545454',
    },
  },
  {
    id: 'MBM_024',
    src: MBM_024,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 264,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#3D7933',
      TEXT_COLOR: '#000000',
    },
  },
  {
    id: 'MBM_025',
    src: MBM_025,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 200,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#C0954A',
      TEXT_COLOR: '#273D2A',
    },
  },
  {
    id: 'MBM_026',
    src: MBM_026,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 90,
      X_PAGE_END: 704,
      Y_HEADER_ICON_START: 110,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#3D752A',
      TEXT_COLOR: '#545454',
    },
  },
  {
    id: 'MBM_027',
    src: MBM_027,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 170,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#675D5C',
      TEXT_COLOR: '#545454',
    },
  },
  {
    id: 'MBM_028',
    src: MBM_028,
    price: 39,
    isTrending: true,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#F9F4ED',
      TEXT_COLOR: '#D9D9D9',
    },
  },
  {
    id: 'MBM_029',
    src: MBM_029,
    price: 59,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#F1D780',
      TEXT_COLOR: '#D9D9D9',
    },
  },
  {
    id: 'MBM_030',
    src: MBM_030,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#6A6025',
      TEXT_COLOR: '#694B1F',
    },
  },
  {
    id: 'MBM_038',
    src: MBM_038,
    price: 39,
    isTrending: false,
    metadata: {
      X_PAGE_START: 54,
      X_PAGE_END: 740,
      Y_HEADER_ICON_START: 75,
      Y_HEADER_ICON_END: 136,
      Y_HEADER_TEXT_START: 144,
      Y_HEADER_TEXT_END: 170,
      Y_TEXT_START: 200,
      DP_WIDTH: 203,
      DP_HEIGHT: 270,
      THEME_COLOR: '#8C8561',
      TEXT_COLOR: '#545143',
    },
  },
]
